<!--- View da visão geral do paciente - Responsável pela visão geral do paciente

Alterado pelos desenvolvedores:
Richard Moraes
Carlos Oliveira
-->
<template>
  <div class="patient-dashboard">
    <div class="container">
      <div class="container-wrapper">
        <div class="container-patient">
          <b-row class="border-default">
            <b-col>
              <div class="white-box w-344">
                <div
                  class="
                    content
                    with-separator
                    d-flex
                    flex-column
                    align-items-center
                  "
                >
                  <div v-if="dashboardData.patient !== null">
                    <Avatar
                      size="100px"
                      :src="`${dashboardData.patient.picture}?x=${moment()}`"
                    />
                  </div>
                  <p class="patient-name" v-if="dashboardData.patient !== null">
                    {{ dashboardData.patient.name }}
                  </p>
                  <p class="inactive-patient" v-if="inactivePatient">
                    (inativo)
                  </p>
                  <p
                    class="medical-record"
                    v-if="dashboardData.patient !== null"
                  >
                    Nº do prontuário:
                    <span>{{
                      dashboardData.patient.medical_record_number
                    }}</span>
                  </p>
                  <div class="mb-3">
                    <b-button
                      v-if="inactivePatient"
                      v-can="'FpPac4'"
                      variant="link"
                      @click="$bvModal.show('confirm-patient-reactivation')"
                      >
                      Ativar paciente
                    </b-button>
                  </div>

                  <div class="glauco" v-if="glaucoIntegration">
                    <p class="title">por Glauco
                      <img src="@/assets/images/glauco_symbol.png" class="logo-glauco">
                    </p>
                    <b-row class="d-flex justify-content-around">
                      <b-col cols="auto">
                        <div
                          class="
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                        >
                          <p class="number">{{frequency}}</p>
                        </div>
                        <p class="info">Dias sem falhas</p>
                      </b-col>
                      <b-col cols="auto">
                        <div class="d-flex justify-content-center">
                          <p class="number">{{parseInt(calcAderency(new Date().getFullYear(), new Date().getMonth()+1), 10)}}%</p>
                          <div class="gauge-area">
                            <VueSvgGauge
                              :start-angle="-90"
                              :end-angle="90"
                              :value="calcAderency(new Date().getFullYear(), new Date().getMonth()+1)"
                              :min="0"
                              :max="100"
                              :separator-step="0"
                              :base-color="'#D9DFF2'"
                              :gauge-color="'#00C773'"
                            />
                          </div>
                        </div>
                        <p class="info">Sucesso no mês</p>
                      </b-col>
                    </b-row>
                  </div>
                  
                  <button
                    class="btn btn-primary btn-block mb-2"
                    @click="startAttendance"
                    v-if="(role === 'DOCTOR' || role === 'DOCTOR_MANAGER') && !inactivePatient"
                  >
                    Iniciar consulta
                  </button>

                  <b-button
                    v-show-feature="'patient-history'"
                    v-if="!inactivePatient"
                    class="btn btn-block mb-24"
                    variant="outline-primary"
                    @click="openPrintHistoryModal"
                    :disabled="!dashboardData?.prontuarios?.length"
                    v-b-tooltip.hover :title="dashboardData?.prontuarios?.length ? 'Imprimir histórico do paciente': 'Impressão indisponível por falta de prontuário'"
                  >
                    <Download class="download-icon" />
                    Imprimir histórico
                  </b-button>

                  <button
                    v-can="'FpAno2'"
                    v-if="!userHasRoles(['DOCTOR_MANAGER', 'DOCTOR'])"
                    class="btn btn-primary btn-block mb-24"
                    @click="startAnnotation"
                  >
                    Criar Anotação
                  </button>

                  <!-- <div class="w-100 d-flex justify-content-center"
                    v-if="role === 'DOCTOR' || role === 'DOCTOR_MANAGER'">
                    <b-button variant="link" class="icon-left"
                      ><MessageCircle /> Chat</b-button>
                      <b-button variant="link" class="icon-left"
                        ><File />Prescrição rápida</b-button
                      >
                  </div> -->
                </div>
                <div
                  class="content with-separator"
                  v-if="dashboardData.patient !== null"
                >
                  <div class="header">
                    <p class="main-title">Perfil</p>
                    <p class="patient-perfil"
                    @click="goPatient">Ver perfil completo</p>
                  </div>

                  <div class="patient-data">
                    <p class="label">Idade</p>
                    <p class="value" v-if="moment().diff(moment(dashboardData.patient.birthday),'days') <= 30 " >
                      {{ moment().diff(moment(dashboardData.patient.birthday),'days') }} dias
                    </p>
                    <p class="value" v-else-if="moment().diff(moment(dashboardData.patient.birthday),'month') > 1 
                    && moment().diff(moment(dashboardData.patient.birthday),'month') < 12" >
                      {{ moment().diff(moment(dashboardData.patient.birthday),'month') }} meses
                    </p>
                    <p class="value" v-else-if="!dashboardData.patient.birthday" >
                      -
                    </p>
                    <p class="value" v-else >
                      {{ moment().diff(moment(dashboardData.patient.birthday),'years') }} anos
                    </p>
                  </div>
                  <div class="patient-data">
                    <p class="label">Data de nascimento</p>
                    <p class="value">{{ dashboardData.patient.birthday ? moment(dashboardData.patient.birthday).format('DD/MM/YYYY') : '-' }}</p>
                  </div>

                  <div class="patient-data">
                    <p class="label">Sexo</p>
                    <p class="value">
                      {{
                        dashboardData.patient.gender === 'M'
                          ? '♂︎ Masculino'
                          : ( dashboardData.patient.gender === 'F' ?
                            '♀ Feminino' :
                            '-'
                          )
                      }}
                    </p>
                  </div>
                  <div v-if="Object.keys(patientIdPrintSettings).length && patientIdPrintSettings.active" class="mt-4">
                    <b-dropdown no-caret class="w-100 patient-id-button" menu-class="w-100" active-class="patient-id-button__active">
                      <template #button-content>
                        <UserCircle />
                        Identificação do paciente
                      </template>
                      <b-dropdown-item @click="printPatientId('bracelet')">Pulseira de identificação</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="content">
                  <div class="header mb-0 healthPlans">
                    <p class="main-title">Convênio</p>
                    <div
                      v-if="
                        dashboardData.patient !== null &&
                        dashboardData.patient.health_plans.length > 0
                      "
                    >
                      <p class="health-plan-name" v-for="(plan, index) in dashboardData.patient.health_plans" :key="index">
                        {{
                          plan.health_plan.fantasy_name !== null
                            ? plan.health_plan.fantasy_name
                            : plan.health_plan.company_name
                        }}
                      </p>
                    </div>
                    <p class="text-right" v-else>-</p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="glaucoIntegration && !['SECRETARY', 'MANAGER'].includes(role)" class="border-default">
            <b-col>
              <div class="white-box w-344">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Mapa de aderência</p>
                  </div>

                  <div class="tab-menu" v-if="glaucoIntegration  && !['SECRETARY', 'MANAGER'].includes(role)">
                    <div class="tab-item"
                    :class="{ active: currentGraph === 'aderency' }"
                    @click="currentGraph = 'aderency'; reloadChart = reloadChart+1">% de aderência</div>
                    <div class="tab-item"
                    :class="{ active: currentGraph === 'failures' }"
                    @click="currentGraph = 'failures'">Dias sem falha</div>
                  </div>

                  <hr />

                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      mt-16
                      mb-16
                    "
                    v-if="glaucoIntegration && !['SECRETARY', 'MANAGER'].includes(role)"
                  >
                    <div class="d-flex align-items-center">
                      <p class="tackMapText" v-if="currentGraph === 'failures'"><span>{{frequency}}</span> Dias sem falhas</p>
                      <p class="tackMapText" v-if="currentGraph === 'aderency'"><span>{{parseInt(calcAderency(new Date().getFullYear(), new Date().getMonth()+1), 10)}}%</span> de aderência</p>
                    </div>
                    <div class="d-flex flex-row">
                      <multiselect
                        v-model="tackMap.month"
                        :options="months"
                        track-by="value"
                        label="name"
                        :option-height="40"
                        :showLabels="false"
                        :searchable="false"
                        :allowEmpty="false"
                        class="small-chevron wider-options"
                        @input="scrollElement('eyedropCount')"
                        v-if="currentGraph === 'failures'"
                      >
                        <template slot="caret">
                          <div class="chevron">
                            <ChevronDown />
                          </div>
                        </template>

                        <template slot="noOptions">
                          Nenhuma opção
                        </template>

                        <template slot="noResult">
                          Nenhum resultado
                        </template>
                      </multiselect>

                      <multiselect
                        v-model="tackMap.period"
                        :options="periods"
                        track-by="value"
                        label="name"
                        :option-height="40"
                        :showLabels="false"
                        :searchable="false"
                        :allowEmpty="false"
                        class="small-chevron wider-options"
                        @input="changeEyedropChart()"
                        v-if="currentGraph === 'aderency'"
                      >
                        <template slot="caret">
                          <div class="chevron">
                            <ChevronDown />
                          </div>
                        </template>

                        <template slot="noOptions">
                          Nenhuma opção
                        </template>

                        <template slot="noResult">
                          Nenhum resultado
                        </template>
                      </multiselect>

                      <multiselect
                        v-model="tackMap.year"
                        :options="years"
                        track-by="value"
                        label="value"
                        :option-height="40"
                        :showLabels="false"
                        :searchable="false"
                        :allowEmpty="false"
                        class="small-chevron wider-options"
                        @input="getEyeDrops()"
                        style="width: 55px"
                      >
                        <template slot="caret">
                          <div class="chevron">
                            <ChevronDown />
                          </div>
                        </template>

                        <template slot="noOptions">
                          Nenhuma opção
                        </template>

                        <template slot="noResult">
                          Nenhum resultado
                        </template>
                      </multiselect>
                    </div>
                  </div>

                  <hr class="mb-24" />
                  <div class="pio-chart"
                  v-if="currentGraph === 'aderency' && glaucoIntegration  && !['SECRETARY', 'MANAGER'].includes(role)">
                    <p style="font-size: 12px; margin-left: 2px">(%)</p>
                    <line-chart :data="eyedropChartData"
                    :options="chartOptions"
                    :key="reloadChart"/>
                  </div>
                  <div class="heat-map"
                  v-if="currentGraph === 'failures' && glaucoIntegration && !['SECRETARY', 'MANAGER'].includes(role)">
                    <ul>
                    <li>Dom</li>
                    <li>Seg</li>
                    <li>Ter</li>
                    <li>Qua</li>
                    <li>Qui</li>
                    <li>Sex</li>
                    <li>Sáb</li>
                    </ul>
                    <perfect-scrollbar
                    id="eyedropCount">
                      <calendar-heatmap
                      class="calendarHeatmap"
                      :values="patientEyedrops"
                      :end-date="formatEyedropDate(tackMap.year.name)"
                      :range-color="['#D9DFF2','#D9DFF2', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00', '#FF6B00']"
                      tooltip-unit="gota(s)"
                      :locale="my_locale">
                      </calendar-heatmap>

                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="border-default mt-3">
            <b-col>
              <div class="white-box w-344" style="max-height: 656px;">
                <div class="content">
                  <div class="header">
                    <p class="main-title-anotation">Anotação sobre o paciente</p>
                    <div class="hide-btn" @click="() => hideList = !hideList">
                      {{ hideList ? 'Ver menos' : 'Ver mais' }}
                    </div>
                  </div>
                  <button
                    v-can="'FpAno2'"
                    class="btn btn-primary btn-block"
                    @click="startAnnotation"
                    :disabled="inactivePatient"
                    v-if="hideList && userHasRoles(['DOCTOR_MANAGER', 'DOCTOR'])"
                  >
                    Adicionar anotação
                  </button>
                </div>
                <div class="annotations-list" v-if="hideList">
                  <perfect-scrollbar>
                    <ul class="annotations" :class="{filled: annotations.length > 0 && canEdit}">
                      <li v-for="(annotation) in annotations" :key="annotation.id">
                        <div class="title">
                          <b-row>
                            <div>
                              <p class="doctor">{{ annotation.professional && annotation.professional.name }} </p>
                              <p>às {{moment(annotation.created_at).format('HH[h]mm')}}
                              do dia {{moment(annotation.created_at).format('DD/MM/YYYY')}}</p>
                            </div>
                            <div>
                              <p class="anotation">{{annotation.content}}</p>
                            </div>
                          </b-row>
                          <div v-if="userHasRoles(['DOCTOR_MANAGER', 'DOCTOR'])">
                              <Icon>
                                <Trash class="icon trash" @click="deleteAnnotation(annotation)"/>
                              </Icon>
                            </div>
                        </div>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                </div>
                <div class="pagination-position">
                  <b-pagination
                    v-model="page"
                    :total-rows="count"
                    :per-page="limit"
                    first-number
                    last-number
                    size="sm"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="container-chart">
          <b-row v-if="!['SECRETARY', 'MANAGER'].includes(role)" class="border-default ml-3 mb-3">
            <b-col>
              <SummaryContainer
                :patientId="$route.params.id"
                :inactivePatient="inactivePatient"
                :summaryData="summaryData"
                @refreshSummary="refreshSummary"
              />
            </b-col>
          </b-row>

           <b-row class="border-default ml-3 mb-3"
             v-if="!['SECRETARY', 'MANAGER'].includes(role) &&
              (dashboardData.acuidadeVisualERefracao.avcc !== null ||
              dashboardData.acuidadeVisualERefracao.avsc !== null ||
              dashboardData.acuidadeVisualERefracao.oculos !== null)">
             <b-col cols="12" class="d-flex">
               <div class="white-box">
                 <div class="content">
                  <div class="header">
                     <p class="main-title">Acuidade Visual e Refração</p>
                     <p class="helper-text">
                       Última:
                       <span>{{
                        dashboardData.acuidadeVisualERefracao.avcc ?
                        dashboardData.acuidadeVisualERefracao.avcc.data : null
                       }}</span>
                     </p>
                   </div>

                   <b-row>
                    <b-col cols="2">
                      <p class="fake-input-title">AVCC</p>

                      <div class="fake-input-group">
                        <div class="fake-input">
                          <div class="eye-area"><EyeFill /> D</div>
                          <p class="text-area">
                            {{
                              dashboardData.acuidadeVisualERefracao.avcc ?
                              dashboardData.acuidadeVisualERefracao.avcc.valor.olhoDireito : null
                            }}
                          </p>
                        </div>
                        <div class="fake-input no-bb">
                          <div class="eye-area"><EyeFill /> E</div>
                          <p class="text-area">
                            {{
                              dashboardData.acuidadeVisualERefracao.avcc ?
                              dashboardData.acuidadeVisualERefracao.avcc.valor.olhoEsquerdo : null
                            }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="2">
                      <p class="fake-input-title">AVSC</p>

                      <div class="fake-input-group">
                        <div class="fake-input">
                          <div class="eye-area"><EyeFill /> D</div>
                          <p class="text-area">
                            {{
                              dashboardData.acuidadeVisualERefracao.avsc ?
                              dashboardData.acuidadeVisualERefracao.avsc.valor.olhoDireito : null
                            }}
                          </p>
                        </div>
                        <div class="fake-input no-bb">
                          <div class="eye-area"><EyeFill /> E</div>
                          <p class="text-area">
                            {{
                              dashboardData.acuidadeVisualERefracao.avsc ?
                              dashboardData.acuidadeVisualERefracao.avsc.valor.olhoEsquerdo : null
                            }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                      <b-col cols="8">
                      <p class="fake-input-title">Refração</p>
                    <!-- <b-col cols="8" v-if="dashboardData.acuidadeVisualERefracao.oculos"> -->
                      <div class="fake-input-group">
                        <div class="fake-input">
                          <div class="eye-area"><EyeFill /> D</div>
                          <p class="text-area type-2 with-br">
                            <span>Esférico</span>
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoDireitoEsferico : null
                            }}
                          </p>
                          <p class="text-area type-2 with-br">
                            <span>Cilíndrico</span>
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoDireitoCilindrico : null
                            }}
                          </p>
                          <p class="text-area type-2 with-br">
                            <span>Eixo</span>
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoDireitoEixo : null
                            }}
                          </p>
                          <p class="text-area type-2">
                            <span>D.P.</span>
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoDireitoDP : null
                            }}
                          </p>
                        </div>
                        <div class="fake-input no-bb">
                          <div class="eye-area"><EyeFill /> E</div>
                          <p class="text-area type-2 with-br">
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoEsquerdoEsferico : null
                            }}
                          </p>
                          <p class="text-area type-2 with-br">
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoEsquerdoCilindrico : null
                            }}
                          </p>
                          <p class="text-area type-2 with-br">
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoEsquerdoEixo : null
                            }}
                          </p>
                          <p class="text-area type-2">
                            {{
                              dashboardData.acuidadeVisualERefracao.oculos ?
                              dashboardData.acuidadeVisualERefracao.oculos.valor.olhoEsquerdoDP : null
                            }}
                          </p>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="d-flex flex-row flex-nowrap ml-3 mb-3">
            <b-col class="d-flex border-default" style="max-height: 656px;">
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Prontuário</p>

                    <b-button variant="link" class="historico fs-14 p-0"
                      v-can="'FpPron11'"
                      v-if="dashboardData.prontuarios.length !== 0"
                        @click="(ev) => showMedicalRecordsModal(ev, $route.params.id)">
                          <Clipboard class="clipboard" /> Visualizar histórico
                    </b-button>
                  </div>
                </div>

                <div class="list-medical-records">
                  <perfect-scrollbar>
                    <p
                      class="info-message-box"
                      v-if="dashboardData.prontuarios.length === 0"
                    >
                      O paciente ainda não teve nenhum prontuário preenchido.
                    </p>
                    <ul>
                      <li
                        v-for="(prontuario, index) of finishedAttendances(dashboardData.prontuarios)"
                        :key="index"
                        :class="`${prontuario.status} ${prontuario.type}`"
                      >
                        <div>
                          <div class="time" v-if="prontuario?.doctor">Dr(a) {{ prontuario?.doctor }}</div>
                          <p class="date"> {{ moment(prontuario.start_datetime).format('DD [de] MMMM YYYY') }}</p>
                          <p class="time">
                            <time>
                              <span v-if="prontuario?.type">
                                {{moment(prontuario.start_datetime).format('HH:mm')}} -
                              </span>
                              {{ moment(prontuario.end_datetime).format('HH:mm') }}
                            </time>
                          </p>
                        </div>
                        <div class="text-right">
                          <div class="text" v-if="prontuario.type === 'ATTENDANCE'">
                            <div class="fc-badge"
                            v-if=" ['SECRETARY', 'MANAGER'].includes(role)"
                              :class="prontuario.status"/>
                              {{
                                !['SECRETARY', 'MANAGER'].includes(role) && prontuario.cidShow ? prontuario.cidShow.cid : translateStatus(prontuario.status)
                              }}
                            </div>
                          <div class="text"
                           v-if="['SURGERY', 'PRE_CONSULTATION'].includes(prontuario.type)"
                          >
                            <div class="fc-badge" :class="`${prontuario.type}`" />
                              {{ translateType(prontuario.type) }}
                            </div>
                          <div class="text" v-else-if="prontuario.name">
                              {{ prontuario.name }}
                          </div>
                         <div class="text" v-else-if="prontuario.type === 'annotation'">
                          <div class="fc-badge ANNOTATION"/>
                           Anotação
                          </div>

                          <router-link
                            v-can="'FpPron2'"
                            v-if="['ATTENDANCE', 'BV_EXAM'].includes(prontuario?.type)"
                            :to="'/pacientes/' + $route.params.id +'/consulta/' + prontuario.id">

                            <b-button variant="link" class="fs-14 p-0">
                              Ver consulta
                            </b-button>
                          </router-link>

                          <router-link
                            v-can="'FpPreCon1'"
                            v-else-if="prontuario?.type === 'PRE_CONSULTATION'"
                            :to="'/pacientes/' + $route.params.id +'/pre-consulta/' + prontuario.id">

                            <b-button variant="link" class="fs-14 p-0">
                              Ver pré-consulta
                            </b-button>
                          </router-link>

                          <router-link
                            v-can="'CenCirurg3'"
                            v-else-if="prontuario?.type === 'SURGERY' || prontuario?.type === 'TEAM_EVOLUTION'"
                            :to="`/centro-cirurgico/dashboard/${prontuario.surgery_information}`"
                            >

                            <b-button variant="link" class="fs-14 p-0">
                              Ver cirurgia
                            </b-button>
                          </router-link>

                          <router-link
                            v-can="'FpPron4'"
                            v-else-if="['EXAM', 'ESSILOR_ATTENDANCE'].includes(prontuario?.type)"
                            :to="`/pacientes/${$route.params.id}/exame/${prontuario.id}`"
                          >
                            <b-button variant="link" class="fs-14 p-0">
                              Ver exame
                            </b-button>
                        </router-link>

                          <!-- VER ANOTAÇÃO -->
                          <router-link
                            v-can="'FpPron3'"
                            v-if="!prontuario.cid && !prontuario.type"
                            :to="
                              '/pacientes/' +
                              $route.params.id +
                              '/anotacoes/' +
                              prontuario.professional_id +
                              '/visualizar'
                            "
                          >
                            <b-button variant="link" class="fs-14 p-0">
                            Ver anotação
                            </b-button>
                          </router-link>
                        </div>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                </div>
              </div>
            </b-col>

            <b-col class="d-flex flex-column border-default ml-2" v-if="!['SECRETARY', 'MANAGER'].includes(role)">
              <div class="white-box" style="height: 100%">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Histórico de colírios</p>
                  </div>
                </div>

                <div class="list-area" style="height: 100%">
                  <perfect-scrollbar>
                    <p
                      class="info-message-box"
                      v-if="dashboardData.historicoDeColirios.length === 0"
                    >
                      O paciente ainda não possui histórico de colírios.
                    </p>
                    <ul class="list">
                      <li
                        v-for="(
                          colirio, index
                        ) of dashboardData.historicoDeColirios"
                        :key="index"
                      >
                        <p class="name">{{ colirio.medicamento }}</p>
                        <p class="info">
                          <span :class="{ orange: !colirio.emUso }"></span>
                          {{ colirio.emUso ? 'Em uso' : 'Cessado' }}
                        </p>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                </div>
              </div>
            </b-col>

          <!-- ANEXAR DOCUMENTOS === SECRETÁRIA -->

            <b-col class="d-flex flex-column justify-content-between border-default" v-else>
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Histórico de documentos</p>
                    <!-- <b-button
                      v-can="'FpPron6'"
                      variant="link"
                      @click="fileType = 'doc'"
                      v-b-modal.attachment-files-generic-modal
                      :disabled="inactivePatient"
                    >
                     <p> <Clip />Anexar arquivo</p>
                    </b-button> -->
                  </div>
                </div>

                <div class="list-area">
                  <perfect-scrollbar
                  :class="{'expanded': expandDocs}">
                    <div
                      class="info-message-box warning"
                      v-if="docs.length === 0"
                    >
                      Os documentos do paciente foram enviados para a nova aba "Documentos e exames" <span @click="$emit('goPatientForm', 4)">visualizar</span>.
                    </div>
                    <ul class="list">
                      <li
                        v-for="(doc, index) of docs"
                        :key="index"
                        @click="setActiveDoc(doc)"
                      >

                        <div class="d-flex container">
                          <span v-if="doc.length === 1" class="icon">
                            <ImageIcon  />
                          </span>
                          <span v-if="doc.length > 1" class="icon">
                            <Gallery />
                          </span>
                          <div class="container-data-files">
                            <div class="data-files">
                              <div class="options" v-if="doc[0].type_id === 'extern'">
                                <External class="more-icon"/>
                                <div class="menu">
                                  <p>Documento criado em outra instituição/clínica</p>
                                </div>
                              </div>
                              <p class="name">{{ getDocumentName(doc) }}</p>
                            </div>
                            <p class="info">{{moment(doc[0].created_at).format('DD [de] MMMM [de] YYYY')}}</p>
                          </div>

                          <div class="ml-auto">
                            <Edit v-can="'FpPron7'" class="attendance-file-option-edit" :changeOnlyName="doc[0]?.path?.includes('Historico Paciente')" @click="(ev) => showEditDocumentModal(ev, doc[0], doc)" />
                            <Delete v-can="'FpPron9'" class="attendance-file-option-delete" @click="(ev) => showExcludeDocumentModal(ev, doc[0])"/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                  <div class="expand" style="margin-top: 20px; height: 30px;">
                    <div style="cursor: pointer" v-if="docs.length !== 0"
                    @click="expandDocs = !expandDocs">
                      <span v-if="!expandDocs">Expandir<ChevronDown /></span>
                      <span v-else>Reduzir<ChevronUp /></span>
                    </div>
                  </div>
                </div>

              </div>
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Histórico de exames</p>
                    <!-- <b-button
                      v-can="'FpPron6'"
                      variant="link"
                      @click="fileType = 'exam'"
                      v-b-modal.attachment-files-generic-modal
                      :disabled="inactivePatient"
                    >
                     <p> <Clip />Anexar arquivo</p>
                    </b-button> -->
                  </div>
                </div>

                <div class="list-area">
                  <perfect-scrollbar
                  :class="{'expanded': expandExams}">
                    <p
                      class="info-message-box warning"
                      v-if="exams.length === 0"
                    >
                      Os exames do paciente foram enviados para a nova aba "Documentos e exames" <span @click="$emit('goPatientForm', 4)">visualizar</span>.
                    </p>
                    <ul class="list">
                      <li
                        v-for="(exame, index) of exams"
                        :key="index"
                        v-b-modal.exams-modal
                        @click="setActiveExam(exame)"
                      >
                      <div class="d-flex container">
                        <span v-if="exame.length === 1" class="icon">
                          <ImageIcon  />
                        </span>
                        <span v-if="exame.length > 1" class="icon">
                          <Gallery />
                        </span>
                        <div class="container-data-files">
                          <div class="data-files">
                            <div class="options" v-if="exame[0].type_id === 'extern'">
                              <External class="more-icon"/>
                              <div class="menu">
                                <p>Exame feito em outra instituição/clínica</p>
                              </div>
                            </div>
                            <p class="name">{{ getDocumentName(exame) }}</p>
                          </div>

                        <p class="info">{{moment(exame[0].created_at).format('DD [de] MMMM [de] YYYY')}}</p>

                        </div>

                        <div class="ml-auto">
                          <Edit v-can="'FpPron8'" class="attendance-file-option-edit" @click="(ev) => showEditExamModal(ev, exam[0], exame)" />
                          <Delete v-can="'FpPron10'" class="attendance-file-option-delete" @click="(ev) => showExcludeExamModal(ev, exame)"/>
                        </div>
                      </div>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                  <div class="expand" style="margin-top: 20px; height: 30px;">
                    <div style="cursor: pointer" v-if="exams.length !== 0"
                    @click="expandExams = !expandExams">
                      <span v-if="!expandExams">Expandir<ChevronDown /></span>
                      <span v-else>Reduzir<ChevronUp /></span>
                    </div>
                  </div>
                </div>

              </div>
            </b-col>

          <!-- FIM -->

          </b-row>

          <!-- ANEXAR DOCUMENTOS !== SECRETÁRIA -->

          <b-row v-if="!['SECRETARY', 'MANAGER'].includes(role)" class="ml-3 mb-3">
            <b-col class="border-default">
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Histórico de documentos</p>
                    <!-- <b-button
                      v-can="'FpPron6'"
                      variant="link"
                      @click="fileType = 'doc'"
                      v-b-modal.attachment-files-generic-modal
                      :disabled="inactivePatient"
                    >
                     <p> <Clip />Anexar arquivo</p>
                    </b-button> -->
                  </div>
                </div>

                <div class="list-area">
                  <perfect-scrollbar
                  :class="{'expanded': expandDocs}">
                    <div
                      class="info-message-box warning"
                      v-if="docs.length === 0"
                    >
                      Os documentos do paciente foram enviados para a nova aba "Documentos e exames" <span @click="$emit('goPatientForm', 4)">visualizar</span>.
                    </div>
                    <ul class="list">
                      <li
                        :class="{ 'opened': openedDocs[doc.id] }"
                        v-for="(doc, index) of docs"
                        :key="index"
                        @click="setActiveDoc(doc)"
                      >

                        <div class="d-flex container">
                          <span v-if="doc.length === 1" class="icon">
                            <ImageIcon  />
                          </span>
                          <span v-if="doc.length > 1" class="icon">
                            <Gallery />
                          </span>
                          <div class="container-data-files">
                            <div class="data-files">
                              <div class="options" v-if="doc[0].type_id === 'extern'">
                                <External class="more-icon"/>
                                <div class="menu">
                                  <p>Documento criado em outra instituição/clínica</p>
                                </div>
                              </div>

                              <p class="name">{{ getDocumentName(doc) }}</p>
                            </div>

                            <p class="info">{{moment(doc[0].created_at).format('DD [de] MMMM [de] YYYY')}}</p>
                          </div>

                          <div class="ml-auto">
                            <Edit v-can="'FpPron7'" class="attendance-file-option-edit" @click="(ev) => showEditDocumentModal(ev, doc[0], doc)" />
                            <Delete v-can="'FpPron9'" class="attendance-file-option-delete" @click="(ev) => showExcludeDocumentModal(ev, doc[0])"/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                  <div class="expand" v-if="docs.length !== 0">
                    <div style="cursor: pointer"
                    @click="expandDocs = !expandDocs">
                      <span v-if="!expandDocs">Expandir<ChevronDown /></span>
                      <span v-else>Reduzir<ChevronUp /></span>
                    </div>
                  </div>
                </div>

              </div>
            </b-col>

            <b-col class="border-default ml-2">
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">Histórico de exames</p>
                    <!-- <b-button
                      v-can="'FpPron6'"
                      variant="link"
                      @click="fileType = 'exam'"
                      v-b-modal.attachment-files-generic-modal
                      :disabled="inactivePatient"
                    >
                     <p> <Clip />Anexar arquivo</p>
                    </b-button> -->
                  </div>
                </div>

                <div class="list-area">
                  <perfect-scrollbar
                  :class="{'expanded': expandExams}">
                    <p
                      class="info-message-box warning"
                      v-if="exams.length === 0"
                    >
                      Os exames do paciente foram enviados para a nova aba "Documentos e exames" <span @click="$emit('goPatientForm', 4)">visualizar</span>.  
                    </p>
                    <ul class="list">
                      <li
                        v-for="(exame, index) of exams"
                        :key="index"
                        v-b-modal.exams-modal
                        @click="setActiveExam(exame)"
                      >
                        <div class="d-flex container">
                          <span v-if="exame.length === 1" class="icon">
                            <ImageIcon  />
                          </span>
                          <span v-if="exame.length > 1" class="icon">
                            <Gallery />
                          </span>
                          <div class="container-data-files">
                            <div class="data-files">
                              <div class="options" v-if="exame[0].type_id === 'extern'">
                                <External class="more-icon"/>
                                <div class="menu">
                                  <p>Exame feito em outra instituição/clínica</p>
                                </div>
                              </div>
                              <p class="name">{{ getDocumentName(exame) }}</p>
                            </div>

                            <div class="exam-info-container">
                              <p class="info">{{moment(exame[0].created_at).format('DD [de] MMMM [de] YYYY')}}
                                <span v-if="exame.at(-1).medical_report"
                                  :class="{ 'orange-background': exame.at(-1).medical_report.status === 'FINISHED',
                                    'blue-background': exame.at(-1).medical_report.status === 'PENDING'}">
                                  {{ exame.at(-1).medical_report.status === 'FINISHED' ? 'Laudado' : 'Laudo pendente' }}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div class="ml-auto">
                            <Edit v-can="'FpPron8'" class="attendance-file-option-edit" @click="(ev) => showEditExamModal(ev, exame[0], exame)" />
                            <Delete v-can="'FpPron10'" class="attendance-file-option-delete" @click="(ev) => showExcludeExamModal(ev, exame)"/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </perfect-scrollbar>
                  <div class="expand">
                    <div style="cursor: pointer" v-if="exams.length !== 0"
                    @click="expandExams = !expandExams">
                      <span v-if="!expandExams">Expandir<ChevronDown /></span>
                      <span v-else>Reduzir<ChevronUp /></span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <!-- FIM -->

          <b-row v-if="currentChart !== null && !['SECRETARY', 'MANAGER'].includes(role)">
            <b-col class="border-default ml-4">
              <div class="white-box">
                <div class="content">
                  <div class="header">
                    <p class="main-title">
                      PIO - Evolução da Pressão Intraocular
                    </p>
                  </div>

                  <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      mb-24
                      position-relative
                    "
                  >
                    <div class="tab-menu">
                      <div
                        class="tab-item"
                        :class="{ active: currentChart === '12meses' }"
                        @click="changeChart('12meses')"
                      >
                        últimos 12 meses
                      </div>
                      <div
                        class="tab-item"
                        :class="{ active: currentChart === '36meses' }"
                        @click="changeChart('36meses')"
                      >
                        últimos 3 anos
                      </div>
                      <div
                        class="tab-item"
                        :class="{ active: currentChart === '60meses' }"
                        @click="changeChart('60meses')"
                      >
                        últimos 5 anos
                      </div>
                    </div>

                    <div class="labels">
                      <div class="label orange">Olho esquerdo</div>
                      <div class="label blue">Olho direito</div>
                    </div>

                    <p class="y-label">(mmHg)</p>
                  </div>

                  <div class="pio-chart" :key="reloadChart">
                    <line-chart :data="chartData" :options="chartOptions" />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <canvas id="chart-helper"></canvas>

    <SuccessModal :message="successMessage" />

    <EditDocumentModal :attendanceDocumentFile="attendanceDocumentFile" :getAllDocs="getAllDocs" />

    <ExcludeDocumentModal
      :document="documentGrouped"
      :getAllDocs="getAllDocs"
      :getDocumentName="getDocumentName"
    />

    <EditExamModal :attendanceExamFile="attendanceExamFile" :getAllExams="getAllExams" />

    <ExcludeExamModal
      :document="documentGrouped"
      :getAllExams="getAllExams"
      :getDocumentName="getDocumentName"
     />

    <MedicalRecordsModal :patientId="$route.params.id" />

    <AppointmentDocsModal
      :docs="examDocs"
      :typeDoc="typeDoc"
      @openDocumentFile="openDocumentFile"
    />

    <AttachmentFilesGenericModal
      :fileType="fileType"
      :patientId="$route.params.id"
      @uploadedFiles="uploadedFiles"
    />

    <DocumentModal :documents="documents" @close-modal="clearDocuments" />

    <EditGroupFilesModal
      :document="document"
      :group="documentGrouped"
      :patientId="$route.params.id"
      :getAllExams="getAllExams"
      :getAllDocs="getAllDocs"
      @clearDocument="clearDocument"
    />

    <PrintHistoryModal
      :firstMedicalRecord="dashboardData?.prontuarios[(dashboardData?.prontuarios?.length-1)]"
      :lastMedicalRecord="dashboardData?.prontuarios[0]"
      :patientId="$route.params.id"
    />

    <ConfirmPatientReactivation
      :confirmedReactivation="confirmedReactivation"
    />

    <BrowserPrintModal v-if="showBrowserPrint" 
      :patientIdInfo="patientIdInfo" 
      :printSettings="patientIdPrintSettings" 
      :type="patientIdType"
      @closeBrowserPrintModal="showBrowserPrint = false"
    />
  </div>
</template>

<script>
import { track } from '@/utils/mixPanel'
import { getCurrentUser, getCurrentClinic, userHasRoles } from '@/utils/localStorageManager'
import { getDocTypes } from '@/utils/documentHelper.js'
import { debounce } from 'lodash';
import { translateType } from '@/utils/attendanceHelper.js'

export default {
  name: 'PatientDashboard',
  metaInfo: {
    title: 'Eyecare - Ficha do Paciente'
  },
  components: {
    AppointmentDocsModal: () => import('../../components/General/AppointmentDocsModal.vue'),
    AttachmentFilesGenericModal: () => import('@/components/General/AttachmentFilesGenericModal'),
    Avatar: () => import('@/components/General/Avatar'),
    UserCircle: () => import('@/assets/icons/user_circle.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
    // Clip: () => import('@/assets/icons/clip.svg'),
    Clipboard: () => import('@/assets/icons/clipboard.svg'),
    Delete: () => import('@/assets/icons/delete.svg'),
    DocumentModal: () => import('@/components/General/DocumentModal'),
    Download: () => import('@/assets/icons/download.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    EditDocumentModal: () => import('@/components/General/EditDocumentModal'),
    EditExamModal: () => import('@/components/General/EditExamModal'),
    EditGroupFilesModal: () => import('@/components/General/EditGroupFilesModal'),
    ExcludeDocumentModal: () => import('@/components/General/ExcludeDocumentModal'),
    ExcludeExamModal: () => import('@/components/General/ExcludeExamModal'),
    External: () => import('@/assets/icons/external.svg'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    Gallery: () => import('@/assets/icons/gallery.svg'),
    ImageIcon: () => import('@/assets/icons/single-image.svg'),
    LineChart: () => import('@/components/LineChart'),
    MedicalRecordsModal: () => import('./MedicalRecordsModal.vue'),
    PrintHistoryModal: () => import('@/components/Patient/Dashboard/PrintHistoryModal.vue'),
    SuccessModal: () => import('@/components/General/SuccessModal'),
    SummaryContainer: () => import('@/components/Patient/Dashboard/SummaryContainer.vue'),
    Trash: () => import ('@/assets/icons/trash.svg'),
    Icon: () => import ('@/components/General/Icon'),
    ConfirmPatientReactivation: () =>
      import('@/components/Patient/ConfirmPatientReactivation'),
    BrowserPrintModal: () => import('@/components/BrowserPrint/BrowserPrintModal.vue')
  },

  created() {
    console.log('prontuarios =>', this.prontuarios)
    this.debounceSearchProcedure = debounce(this.searchProcedure, 500);
  },

  async mounted() {
    this.getEyeDrops()
    this.getAllEyeDrops()
    await this.getPatientSummary()

    this.role = this.clinic.role

    this.userLogged = this.user

    await this.getDashboardData();

    // await this.getAllDocs();
    // await this.getAllExams();
    this.getAnnotations();
    this.getPatientIdPrintSetttings();
  },
  watch: {
    fileName: function(val) {
      this.fileName = this.fileName.replace('-', '').replace('_', '').replace('~', '').replace('.', '').replace('@', '').replace('!', '').replace('#', '').replace('$', '').replace('%', '').replace('*', '').replace('+', '').replace('^', '').replace('[', '').replace(']', '').replace('{', '').replace('}', '').replace('(', '').replace(')', '').replace(';', '').replace(':', '').replace('/', '').replace('\\', '').replace('?', '').replace('|', '').replace('<', '').replace('>', '').replace(',', '').replace('"', '').replace("'", "").replace('´', '').replace('`', '').replace('=', '').replace(' ', '')
    },

    selectedProcedure: function(newVal){
      if(!this.selectedProcedure)
        this.canCreateMedicalReportFile = false

      this.procedureIsLaudable(newVal.value)
    },

    page: function() { this.getAnnotations() },
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      summaryData: [],
      attendanceDocumentFile: null,
      attendanceExamFile: null,
      successMessage: '',
      attendanceId: null,
      role: '',
      File: [],
      fileName: '',
      file_type: 'intern',
      examsCurrentTab: 'exame',
      laudo: {
        form: null
      },
      openedDocs: {},
      allEyedrops: [],
      expandDocs: false,
      expandExams: false,
      userLogged: [],
      activeExam: [],
      exams: [],
      docs: [],
      examDocs: [],
      fileType: 'exam',
      frequency: 0,
      glaucoIntegration: false,
      periods: [
        { value: '01', name: 'Ano Todo' },
        { value: '02', name: 'Primeiro Semestre' },
        { value: '03', name: 'Segundo Semestre' }
      ],
      currentGraph: 'failures',
      reloadChart: 0,
      my_locale: {
        months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        on: 'on',
        less: 'Less',
        more: 'More'
      },
      fullMonths: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      patientEyedrops: [],
      eyedropYear: new Date(new Date().getFullYear(), 0, 1),
      eyedropChartData: {
        labels: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ],
        datasets: [
          {
            label: '%',
            borderColor: '#FF6B00',
            backgroundColor: '#FF6B00',
            pointBackgroundColor: '#FF6B00',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0.3,
            data: []
          }
        ]
      },
      healthPlanImageUrl: process.env.VUE_APP_API_URL + 'storage/images/health-plans/',
      dashboardData: {
        patient: null,
        doencasOftalmologicas: [],
        cirurgiasProcedimentosOftalmologicos: [],
        alergias: [],
        doencasPrevias: [],
        medicamentosEmUso: [],
        antecedentesFamiliaresOftalmologicos: [],
        acuidadeVisualERefracao: {
          avcc: null,
          avsc: null,
          refracao: null,
          refracaoEstatica: null,
          refracaoDinamica: null
        },
        prontuarios: [],
        historicoDeColirios: [],
        exames: [],
        pio: null
      },
      currentChart: null,
      chartData: {
        labels: [
          '10 jan 20',
          '05 fev 20',
          '27 mar 20',
          '15 mai 20',
          '7 jun 20',
          '7 jun 20',
          '7 jun 20',
          '7 jun 20',
          '7 jun 20',
          '7 jun 20',
          '7 jun 20',
          '7 jun 20'
        ],
        datasets: [
          {
            label: 'Olho esquerdo',
            borderColor: '#FF6B00',
            backgroundColor: '#FF6B00',
            pointBackgroundColor: '#FF6B00',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0,
            data: [14, 16, 15, 13, 16, 14, 14, 14, 16, 17, 14, 16]
          },
          {
            label: 'Olho direito',
            borderColor: '#305BF2',
            backgroundColor: '#305BF2',
            pointBackgroundColor: '#305BF2',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0,
            data: [17, 16, 14, 16, 17, 15, 16, 12, 18, 19, 17, 17]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#D9DFF2'
              },
              ticks: {
                stepSize: 1,
                fontSize: 10,
                fontColor: '#8696AC'
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                borderDash: [3, 2],
                color: '#D9DFF2'
              },
              ticks: {
                fontSize: 10,
                fontColor: '#8696AC'
              }
            }
          ]
        },
        tooltips: {
          mode: 'label',
          bodyAlign: 'center'
        }
      },
      tackMap: {
        month: { value: '01', name: 'Janeiro' },
        year: { value: new Date().getFullYear() % 100, name: '2022'},
        period: { value: '01', name: 'Ano Todo'}
      },
      months: [
        { value: '1', name: 'Janeiro' },
        { value: '2', name: 'Fevereiro' },
        { value: '3', name: 'Março' },
        { value: '4', name: 'Abril' },
        { value: '5', name: 'Maio' },
        { value: '6', name: 'Junho' },
        { value: '7', name: 'Julho' },
        { value: '8', name: 'Agosto' },
        { value: '9', name: 'Setembro' },
        { value: '10', name: 'Outubro' },
        { value: '11', name: 'Novembro' },
        { value: '12', name: 'Dezembro' }
      ],
      years: [
        { value: '20', name: '2020' },
        { value: '21', name: '2021' },
        { value: '22', name: '2022' },
        { value: '23', name: '2023' },
        { value: '24', name: '2024' },
        { value: '25', name: '2025' }
      ],
      typeDoc: getDocTypes(),
      selectedProcedure: null,
      selectedLaudableResponsibles: [],
      proceduresOptions: [],
      medicalReportResponsibles: [],
      canCreateMedicalReportFile: false,
      documents: [],
      documentGrouped: [],
      document: null,
      annotationForm: {
        id: '',
        content: ''
      },
      annotationFormUpdate: {
        id: '',
        content: ''
      },
      annotations: {
        id: '',
        content: '',
      },
      canEdit: false,
      hideList: true,
      page: 1,
      limit: 0,
      count: 0,
      showBrowserPrint: false,
      patientIdInfo: {},
      patientIdPrintSettings: {},
      patientIdType: ''
    }
  },
  computed: {
    inactivePatient() {
      return this.dashboardData.patient?.delete_reason;
    }
  },
  methods: {
    translateType,
    userHasRoles,
    finishedAttendances(attendances) {
      return attendances.filter(function(attendance) {
        if(attendance.end_datetime) {
          return attendance
        }
      })
    },
    getDocumentName(arrayDocument) {
      const document = arrayDocument[0]
      if (!document) return ''
      if (arrayDocument.length > 1) {
        return `${document.group_name}`
      } else if (document.custom_name) {
        return document.custom_name
      } else if(document.type_id) {
        return this.typeDoc[document.type_id]
      }
    },
    clearDocuments() {
      this.documents = []
    },
    async getPatientSummary() {
      try {
        const types = ['antecedentes-oftalmologicos', 'cirurgias-oftalmologicas-anteriores', 'alergias', 'antecedentes-pessoais', 'outros-medicamentos-em-uso',
          'antecedentes-familiares']
        const response = await this.api.getPatientSummary(this.$route.params.id, types);
        this.summaryData = response.data
      } catch (error) {
        console.log(error);
      }
    },
    refreshSummary(data, id) {
      if (id) {
        const index = this.summaryData.findIndex(item => item.id === id)
        this.summaryData.splice(index, 1)
      } else {
        this.summaryData.push(data)
      }
      this.summaryData.sort()
    },
    getCid(prontuario) {
      if (!prontuario.cid || !prontuario.cid.length) return null
      return prontuario.cid.find(cid => cid.predominante) || prontuario.cid[0];
    },
    async createExam(name){
      if (name === '' || !name) {
        this.$toast.error('Não foi possível criar o exame, verifique se o nome digitado está correto.')
        return;
      }
      const isLoading = this.$loading.show();
      const props = {
          clinic_id: this.clinic.id,
          name: name,
          type: 'EXAM',
          procedure_tuss_id: null,
          procedure_sus_id: null,
          description: 'Exame criado durante anexo de arquivo',
          duration: 0,
      }
      await this.api.createClinicProcedure(props)
      .then(res => {
        this.selectedProcedure = { 'value': res.data.id, 'label': name }
        this.$toast.success('Exame criado com sucesso!');
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    changeSelectedResponsibles(newValue){
        this.medicalReportResponsibles = newValue
    },
    showExcludeDocumentModal(ev, documentArray) {
      ev.stopPropagation()
      if (Array.isArray(documentArray)) {
        this.documentGrouped = documentArray
      } else {
        this.documentGrouped = [documentArray]
      }

      this.$bvModal.show('exclude-document-modal')
    },
    showEditDocumentModal(ev, doc, documentArray) {
      ev.stopPropagation()
      this.document = doc
      this.attendanceDocumentFile = doc
      if (documentArray.length > 1) {
        this.documentGrouped = documentArray
        this.$bvModal.show('edit-groups-file-modal')
        return
      }
      this.$bvModal.show('edit-document-modal')
    },
    showExcludeExamModal(ev, examArray) {
      ev.stopPropagation()
      if (Array.isArray(examArray)) {
        this.documentGrouped = examArray
      } else {
        this.documentGrouped = [examArray]
      }
      this.$bvModal.show('exclude-exam-modal')
    },
    showEditExamModal(ev, exam, examArray) {
      ev.stopPropagation()
      this.document = exam
      this.attendanceExamFile = exam
      if (examArray.length > 1) {
        this.documentGrouped = examArray
        this.$bvModal.show('edit-groups-file-modal')
        return
      }
      this.$bvModal.show('edit-exam-modal')
    },
    clearDocument() {
      this.documentGrouped = []
      this.document = null
    },
    showMedicalRecordsModal(ev, patientId ) {
      ev.stopPropagation()
      this.$bvModal.show('medical-records-modal')
    },
    translateStatus(value){
      var res = ''
      switch (value) {
        case 'CLOSED':
          res = 'Finalizado'
          break;
        case 'OPENED':
          res = 'Não finalizado'
          break;
        case 'PAUSED':
          res = 'Não finalizado'
          break;

        default:
          res = 'Finalizado'
          break;
      }

      return res
    },
    goPatient() {
      this.$emit('goPatientForm', 1)
    },
    setActiveDoc(doc){
      if(['SECRETARY', 'MANAGER'].includes(this.role) && doc[0].type_id === '0') {
        this.$toast.error('Você não possui permissão para visualizar esse documento!');
        return
      }
      this.openDocumentFile(doc)
    },
    async setActiveExam(exam){
      if(['SECRETARY', 'MANAGER'].includes(this.role) && exam[0].type_id === '0') {
        this.$toast.error('Você não possui permissão para visualizar esse documento!');
        return
      }

      if (exam.at(-1).medical_report) {
        this.examDocs = []
        const examDocument = exam
        examDocument.type_id = null
        this.examDocs.push(examDocument)
        if (exam.at(-1).medical_report.attendance_id) {
          const res = await this.api.getAttendanceFiles(exam.at(-1).medical_report.attendance_id)
          const file = res.data.find( doc => { return doc.type_id === '11' })
          if (file) {
            this.examDocs.push(file)
          }
        }
        this.$bvModal.show('appointment-docs-modal')
        return
      }
      this.openDocumentFile(exam)
    },
    activateExam(exam) {
      this.activeExam = exam
    },
    openDocumentFile(document) {
      this.documents = document
      this.$bvModal.show('document-modal')
    },
    createPdf(exam){
      if(exam.type === 'pdf' && !exam.url){
        const byteCharacters = atob(exam.file);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: 'application/pdf'});
        var url = URL.createObjectURL(blob);
        exam.url = url;
      }
    },
    changeEyedropChart(){
      switch(this.tackMap.period.value){
        case '01':
          this.eyedropChartData.labels=
          [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
          ]
          this.eyedropChartData.datasets[0].data = []
          this.getEyeDrops()
          break
        case '02':
          this.eyedropChartData.labels=
          [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          ]
          this.eyedropChartData.datasets[0].data = []
          this.getEyeDrops()
          break
        case '03':
          this.eyedropChartData.labels=
          [
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
          ]
          this.eyedropChartData.datasets[0].data = []
          this.getEyeDrops()
          break
      }
    },
    scrollElement(scrollElement){
      var elementId = document.getElementById(scrollElement)
      elementId.scrollLeft = (this.tackMap.month.value-1)*115
    },
    formatEyedropDate(value){
      var date = new Date(value);
      date.setFullYear(date.getFullYear() + 1);

      return date+'-01-01'
    },
    calcAderency(year, month){

      var aderencyMonths= [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
      ]

      var distinctDrops = []
      this.patientEyedrops.forEach(eyedropDate => {
         distinctDrops.push(this.moment(eyedropDate.date).format('YYYY-MM-DD'))
      })

      new Set(distinctDrops).forEach(drop => {
        var dateEyedrop = drop
        var eyedropDateMonth = this.moment(dateEyedrop).format('MM');
        if(this.moment(year, 'YYYY').format('YYYY') === this.moment(dateEyedrop).format('YYYY')){
          aderencyMonths[parseInt(eyedropDateMonth)]++
        }
      })

      month = parseInt(month)

      var allDrops = 1
      this.allEyedrops.find(d =>{
        if(this.moment(d.datetime).format('YYYY-MM') === this.moment(year+'-'+month, 'YYYY-MM').format('YYYY-MM')){
          allDrops ++
        }
      })

      var res = (aderencyMonths[month] * 100) / allDrops

      return res
    },
    async getAllDocs() {
      try {
        const response = await this.api.getAllDocs(this.$route.params.id)
        this.docs = response.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getAllExams() {
      try {
        const response = await this.api.getAllExams(this.$route.params.id)
        this.exams = response.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async uploadedFiles() {
      this.fileType === 'doc' ?
        await this.getAllDocs() :
        await this.getAllExams()
    },
    getEyeDrops() {
      this.api
        .getPatientEyeDrops(this.$route.params.id)
        .then(res => {
          const resData = res.data

          if(resData){

            this.glaucoIntegration = true
            this.patientEyedrops = []
            resData.forEach(eyedrop => {

            var value = {date: eyedrop.updated_at, count: eyedrop.additional_data.amountOfDrops}

            this.patientEyedrops.push(value)
            })

            var compareDate = this.moment().format('YYYY-MM-DD')
            this.patientEyedrops.find(d => {
              while(compareDate === this.moment(d.date).format('YYYY-MM-DD')){
                compareDate = this.moment(compareDate).subtract(1, 'day').format('YYYY-MM-DD')
                this.frequency ++
              }
            })

            switch(this.tackMap.period.value){
                    case '01':
                      this.eyedropChartData.datasets[0].data = []
                      this.eyedropChartData.datasets[0].data.push(parseInt(this.calcAderency(this.tackMap.year.name, 1), 10), parseInt(this.calcAderency(this.tackMap.year.name, 2), 10), parseInt(this.calcAderency(this.tackMap.year.name, 2), 10), parseInt(this.calcAderency(this.tackMap.year.name, 4), 10), parseInt(this.calcAderency(this.tackMap.year.name, 5), 10), parseInt(this.calcAderency(this.tackMap.year.name, 6), 10), parseInt(this.calcAderency(this.tackMap.year.name, 7), 10), parseInt(this.calcAderency(this.tackMap.year.name, 8), 10), parseInt(this.calcAderency(this.tackMap.year.name, 9), 10), parseInt(this.calcAderency(this.tackMap.year.name, 10), 10), parseInt(this.calcAderency(this.tackMap.year.name, 11), 10), parseInt(this.calcAderency(this.tackMap.year.name, 12), 10))
                      break
                    case '02':
                      this.eyedropChartData.datasets[0].data = []
                      this.eyedropChartData.datasets[0].data.push(parseInt(this.calcAderency(this.tackMap.year.name, 1), 10), parseInt(this.calcAderency(this.tackMap.year.name, 2), 10), parseInt(this.calcAderency(this.tackMap.year.name, 2), 10), parseInt(this.calcAderency(this.tackMap.year.name, 4), 10), parseInt(this.calcAderency(this.tackMap.year.name, 5), 10), parseInt(this.calcAderency(this.tackMap.year.name, 6), 10))
                      break
                    case '03':
                      this.eyedropChartData.datasets[0].data = []
                      this.eyedropChartData.datasets[0].data.push(parseInt(this.calcAderency(this.tackMap.year.name, 7), 10), parseInt(this.calcAderency(this.tackMap.year.name, 8), 10), parseInt(this.calcAderency(this.tackMap.year.name, 9), 10), parseInt(this.calcAderency(this.tackMap.year.name, 10), 10), parseInt(this.calcAderency(this.tackMap.year.name, 11), 10), parseInt(this.calcAderency(this.tackMap.year.name, 12), 10))
                      break
                  }

            this.reloadChart = this.reloadChart+1
          }
          else{
            this.glaucoIntegration = false
          }
        }).catch(error => {
          console.log(error)
        })
    },
    getAllEyeDrops() {
      this.api
        .getAllPatientEyeDrops(this.$route.params.id)
        .then(res => {
          const resData = res.data
          this.allEyedrops = res.data

          var response = []

          resData.forEach(el => {
            if(this.moment(el.datetime).format('MM-YYYY') === this.moment('2021-11-16 01:25:00.000').format('MM-YYYY')){
              response.push(el)
            }
          });
        })
        .catch(err => {
          console.log('Err', err)
        })
    },
    startAnnotation() {
      const user = JSON.parse(localStorage.getItem('user'));
      this.$router.push(
        '/pacientes/' + this.$route.params.id + '/anotacoes/' + user.id + '/criar'
      )
    },
    async startAttendance() {
      const isLoading = this.$loading.show()
      try {
        const formId = await this.getDefaultForm()
        const response = await this.api.startAttendance(this.clinic.id, this.$route.params.id, this.$route.params.appointmentId || null, formId)
        this.$router.push(
          '/pacientes/' + this.$route.params.id + '/consulta/' + response.data.id
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getDefaultForm() {
      try {
        const response = await this.api.getForms(this.clinic.id, this.user.id, 'ATTENDANCE');
        const form = response.data.find(f => f.default);
        let defaultForm = null;
        if (form) {
          defaultForm = form.id
        }
        return defaultForm
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async procedureIsLaudable(procedureId){
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      const laudableProcedures = await this.api.allLaudableProcedures(clinic.id)
      if( laudableProcedures.data.find( el => { return el.clinic_procedure_id === this.selectedProcedure.value }) ){
        this.canCreateMedicalReportFile = true
      }
      else{
        this.canCreateMedicalReportFile = false
      }
    },
    async uploadFile() {
      const clinic = JSON.parse(localStorage.getItem('clinic'))
      var files = this.File
      const fileName = this.fileName
      const fileType = this.file_type
      files.group = fileName

      const dataForm = new FormData();
      dataForm.append(`file`, files);
      dataForm.append(`fileName`, fileName)
      dataForm.append(`type`, fileType)
      const distinctType = this.fileType === 'documento' ? 'doc' : 'exam';

      const isLoading = this.$loading.show();
      this.api
        .uploadDocs(clinic.id, this.$route.params.id, distinctType, dataForm)
        .then(async res => {
          files.sendStatus = true
          files.storedFileName = res.data
          distinctType === 'doc' ? await this.getAllDocs() : await this.getAllExams();
          this.$bvModal.hide('add-exams-modal');
          this.$toast.success('Arquivo enviado com sucesso');
          track('documento_anexado', {
            clinic: this.clinic.name,
            type: distinctType,
          });

          if( this.fileType === 'exam' && this.canCreateMedicalReportFile ){
              const responsiblesIds = []
              await this.medicalReportResponsibles.map((responsible) => {
                responsiblesIds.push(responsible.value)
              })

              await this.api.createOrUpdateMedicalReportFile(res.data.id, this.selectedProcedure.value, 'PENDING', responsiblesIds, null)
              .then(response => {
                this.medicalReportResponsibles = []
              })
          }
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.error)

              this.$router.push(
                '/pacientes/' +
                  this.$route.params.id +
                  '/consulta/' +
                  err.response.data.id
              )
            }
          }
        })
        .finally(() => isLoading.hide());
    },
    dragFile(e) {
      const file = e.dataTransfer.files[0];
      const fileType = file.type;
      this.fileExtension = fileType.split('/')[1];
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : '';
      if (![
        'pdf','jpg','jpeg','png', 'jps',
        'application/pdf','image/jpg',
        'image/jpeg','image/png', 'tiff'
      ].includes(fileType || nameExt)) {
        return this.$toast.error('Formato de arquivo não suportado!');
      }
      this.File = e.dataTransfer.files[0];
    },
    addFile(e) {
      this.File = e.target.files[0]
    },
    removeFile() {
      this.File = []
    },
    changeChart(chart) {
      let newChartData = null
      if (chart === '12meses') {
        newChartData = this.dashboardData.pio.ultimos12meses
      } else if (chart === '36meses') {
        newChartData = this.dashboardData.pio.ultimos36meses
      } else if (chart === '60meses') {
        newChartData = this.dashboardData.pio.ultimos60meses
      }

      this.currentChart = chart

      this.chartData = {
        labels: newChartData.labels,
        datasets: [
          {
            label: 'Olho esquerdo',
            borderColor: '#FF6B00',
            backgroundColor: '#FF6B00',
            pointBackgroundColor: '#FF6B00',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0,
            data: newChartData.olhoEsquerdo
          },
          {
            label: 'Olho direito',
            borderColor: '#305BF2',
            backgroundColor: '#305BF2',
            pointBackgroundColor: '#305BF2',
            pointRadius: 4,
            pointHoverBackgroundColor: '#FFFFFF',
            pointHoverBorderWidth: 2,
            lineTension: 0,
            data: newChartData.olhoDireito
          }
        ]
      }
      this.reloadChart++
    },
    cleanModal() {
      this.fileName = ""
      this.file_type = "intern"
      this.File = []
    },
    changeLaudableResponsibles(newValue){
        this.selectedLaudableResponsibles = newValue
    },
    async searchProcedure(query){
      if(query.length > 0){
        this.proceduresOptions = []
        const procedures = await this.api.searchClinicProcedures(this.clinic.id, query, null)
        this.proceduresOptions = procedures.data.map((el) => {
          return {
            'value': el.id,
            'label': el.name
          }
        })
      }
    },
    openPrintHistoryModal(){
      this.$bvModal.show('print-history-modal')
    },
    getAnnotations(){
      const isLoading = this.$loading.show()

      this.api
        .getAnnotations(this.page, this.clinic.id, this.$route.params.id)
        .then(res => {
          this.annotations = res.data.data;
          this.count = res.data.total;
          this.limit = res.data.per_page;

          this.annotationForm = {
            id: '',
            content: ''
          }

          if(this.$route.params.action === 'criar'){
            this.startAnnotation()
          }
        })
        .finally(() => {
          isLoading.hide()
        })
    },
    deleteAnnotation(annotation){
      
      this.$swal({
        title: 'Excluir anotação',
        html: `<div class="body-alert">A exclusão de uma anotação <span>não poderá ser desfeita.</span></div> <div class="body-alert">Deseja realmente excluir esta anotação?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        customClass: {
          cancelButton: 'btn btn-outline-danger',
          confirmButton: 'btn btn-primary'
        },
       }).then(res => {
        if (res.isConfirmed) {
          const isLoading = this.$loading.show()
          annotation.content = ''
          this.annotationFormUpdate = annotation
          this.api
            .saveAnnotation(this.clinic.id, this.$route.params.id, this.annotationFormUpdate)
            .then(() => {
              this.$toast.success('Anotação apagada com sucesso!');
              this.getAnnotations();
            })
            .catch(err => this.$toast.error(err.message))
            .finally(() => {
              isLoading.hide()
            })
        }
      })
    },
    async getDashboardData() {
      const isLoading = this.$loading.show()
      this.api
      .showPatientDashboard(this.clinic.id, this.$route.params.id)
      .then(res => {
        const resData = res.data

        this.dashboardData = resData
        this.changeChart('12meses')

        this.dashboardData.prontuarios.map(prontuario => {
          prontuario.cidShow = this.getCid(prontuario);
          return prontuario;
        })

        this.dashboardData.prontuarios.sort(function(a, b) {
            var c = new Date(a.start_datetime);
            var d = new Date(b.start_datetime);
            return d-c;
        });
      })
      .catch(err => {
        this.$toast.error(err.message)
        this.$router.push('/pacientes')
      })
      .finally(() => {
        isLoading.hide()
      })
    },
    confirmedReactivation(confirmed) {
      this.$bvModal.hide('confirm-patient-reactivation')
      if (!confirmed) return

      const isLoading = this.$loading.show()
      this.api
        .reactivatePatient(this.dashboardData.patient.id)
        .then(response => {
          this.$toast.success('Paciente reativado com sucesso!')
          this.getDashboardData()
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    printPatientId(type){
      this.patientIdType = type
      this.patientIdInfo = this.dashboardData.patient
      this.showBrowserPrint = true
    },
    getPatientIdPrintSetttings(){
      try {
        this.api.getBraceletPrintSettings(this.clinic.id)
        .then(({data}) => {
          this.patientIdPrintSettings = data
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  },
}
</script>

<style lang="scss">
.swal2-modal {
  .swal2-title {
    font-size: 18px;
    text-align: left;
    color: var(--type-active);
    border: 1px solid var(--neutral-200);
    padding-bottom: 20px;
  }

  .swal2-input-label {
    font-size: 16px;
    font-weight: 600;
    margin-right: 84%;
    margin-top: 5%;
    color: var(--dark-blue);
  }

  .swal2-select {
    margin-top: 1%;
    border-radius: 8px;
    padding: 0.375em 0.625em;
    border-color: var(--type-placeholder);
    font-weight: 400;
    color: var(--type-active);
    cursor: pointer;
  }

  button {
    font-weight: bold;
  }

  .btn-outline-danger {
    border: 1px solid #F63220 !important;
    background-color: transparent;
    color: #F63220;
    border-radius: 8px;
  }

  .btn-outline-danger:hover {
    background-color: #F63220;
    color: var(--neutral-000);
  }
}

#add-exams-modal {
  font-family: Nunito Sans;
  font-style: normal;
  color: #525C7A;

  .modal-dialog {
    max-width: 600px !important;
  }

  .modal-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  .body {
    .file-inf {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #0C1D59;
        margin: 5px 0px;
      }

      .file-name {
        height: 38px;
        margin-bottom: 15px;
        padding: 10px;
        border: 1px solid #C6CEEB;
        box-sizing: border-box;
        border-radius: 8px;
        font-family: Nunito Sans;
        font-style: normal;
        color: #525C7A;
      }

      .file-name:focus {
        outline: none !important;
      }

      .file-type {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .modal-input-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      span {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: var(--dark-blue);
      }
    }

    .file-atach {
      .file-container{
        height: 100px;
        background: transparent;
        border-top: 1px solid #D9DFF2;
        border-bottom: 1px solid #D9DFF2;

        &.without-file {
          height: 160px;
          border: 1px dashed #305BF2;
          box-sizing: border-box;
          border-radius: 8px;
          background: #F4F5FB;
        }

        .drag-area {
          width: 100%;
          height: 100%;
          padding: 20px;
          font-weight: bold;
          font-size: 16px;
          line-height: 130%;
          color: #525C7A;
          text-align: center;

          .separator {
            margin: 12px 0px;
          }

          .add-btn {
            background: #0B30B2;
            border-radius: 8px;
            color: #ffffff;
            padding: 8px 15px;
            cursor: pointer;

            font-family: Nunito Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
          }

          #add-file-input {
              display: none;
          }

          .file {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            align-content: center;

            svg {
              width: 42px !important;
              height: 42px !important;
            }

            .close {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
      }
    }

    .save-file {
      float: right;
      padding: 15px;
      margin-top: 20px;
      color: #ffffff;
      background: #305BF2;
      border-radius: 8px;
    }

    .disabled-btn {
      background: var(--neutral-400);
    }
  }
}

.patient-dashboard {
  height: 100%;
  padding: 30px 24px;
  text-align: left;

  .breadcrumbs {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 16px;

    .chevron {
      width: 24px;
      stroke: var(--neutral-600);
      margin: 0 8px;
    }

    .link {
      color: var(--blue-500);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    column-gap: 1rem;

    .border-default {
      border: 1px solid #D9DFF2;
      border-radius: 8px;

    }
    .container-wrapper {
      display: flex;
      gap: 16px;
      .container-patient {
        min-width: 350px;
      }
      .container-chart {
        max-width: calc(100% - 350px);
      }
    }
    .icon {
      svg {
        stroke: var(--neutral-500);
        width: 24px;
        height: 24px;
      }
      margin-right: 5%;
    }

    .white-box {
      width: 100%;
      background-color: var(--neutral-000);
      border-radius: 8px;
      margin-bottom: 16px;

      &.w-344 {
        width: 344px;
      }

      .content {
        padding: 24px;

        &.with-separator {
          border-bottom: 1px solid var(--neutral-200);
        }

        .hide-btn {
          color: var(--blue-500);
          cursor: pointer;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .healthPlans {
          display: flex;
          flex-direction: column;
          text-align: start;
          align-items: flex-start !important;

          .main-title {
            margin-bottom: 15px !important;
          }

          p {
            width: 100%;
          }
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .main-title {
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 18px;
            color: var(--dark-blue);
            margin-bottom: 0px;
          }

          .main-title-anotation {
            font-family: 'Red Hat Display';
            font-weight: 700;
            font-size: 15px !important;
            color: var(--dark-blue);
            margin-bottom: 0px;
          }
          .patient-perfil {
            color: #305BF2;
            font-family: Nunito Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: right;
            cursor: pointer;
          }

          .add-file {
            display: flex;
            align-content: center;
            align-items: center;
            color: #305BF2;
            cursor: pointer;

            svg {
              margin-right: 5px;
            }
          }

          .helper-text {
            font-weight: 600;
            font-size: 10px;
            color: var(--type-placeholder);

            span {
              font-size: 12px;
              color: var(--type-active);
            }
          }
        }

        .patient-abstract {
          .sub-title {
            font-family: 'Red Hat Display';
            font-weight: 500;
            font-size: 14px;
            color: var(--dark-blue);
            margin-bottom: 16px;
          }
        }

        .fake-input-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .fake-input-group {
          display: flex;
          flex-direction: column;
          border: 1px solid var(--neutral-300);
          border-radius: 8px;

          .fake-input {
            flex: 1;
            min-height: 55px;
            border: 8px;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid var(--neutral-300);
            justify-content: center;

            &.no-bb {
              border-bottom: 0;

              .eye-area {
                border-radius: 0 0 0 8px;
              }
            }

            .eye-area {
              width: 60px;
              background-color: var(--neutral-100);
              border-right: 1px solid var(--neutral-300);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px 0 0 0;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;

              svg {
                width: 16px;
                height: 16px;
                fill: var(--type-active);
                margin-right: 4px;
              }
            }

            .text-area {
              flex: 1;
              line-height: 55px;
              margin-left: 16px;
              font-weight: 400;
              font-size: 16px;
              color: var(--type-active);

              &.type-2 {
                position: relative;
                margin-left: 0;
                text-align: center;

                span {
                  width: 100%;
                  position: absolute;
                  top: -19px;
                  left: 0;
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 16px;
                  color: var(--type-active);
                }
              }

              &.with-br {
                border-right: 1px solid var(--neutral-300);
              }
            }
          }
        }

        .gray-box {
          background-color: var(--neutral-100);
          padding: 16px 24px;
          border-radius: 8px;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;

          .sub-title {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
          }

          .mb-4 {
            margin-bottom: 4px !important;
          }

          .tag-eye {
            width: 32px;
            height: 16px;
            background-color: var(--neutral-200);
            border-radius: 4px;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 10px;
              height: 10px;
              fill: var(--neutral-600);
              margin-right: 4px;
            }
          }

          thead {
            font-weight: 700;
            font-size: 12px;
            color: var(--type-active);
          }

          tr {
            td,
            th {
              padding: 0px 8px 4px 8px;
            }

            &:last-child {
              td,
              th {
                padding-bottom: 0;
              }
            }
          }

          .info {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: var(--type-active);
          }
        }

        .tab-menu {
          display: inline-block;
          background-color: var(--neutral-100);
          border-radius: 8px;

          .tab-item {
            display: inline-block;
            padding: 4px 16px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: var(--type-active);
            border-radius: 8px;
            cursor: pointer;

            &.active {
              background-color: var(--blue-500);
              color: var(--neutral-000);
            }
          }
        }

        .labels {
          display: flex;
          flex-direction: row;

          .label {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
            padding-left: 40px;
            position: relative;

            &:last-child {
              margin-left: 24px;
            }

            &:before {
              width: 24px;
              height: 2px;
              background-color: var(--orange);
              border-radius: 2px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              content: ' ';
            }

            &:after {
              width: 8px;
              height: 8px;
              background-color: var(--orange);
              border-radius: 4px;
              position: absolute;
              left: 7.5px;
              top: 50%;
              transform: translateY(-50%);
              content: ' ';
            }

            &.orange {
              &:before {
                background-color: var(--orange);
              }

              &:after {
                background-color: var(--orange);
              }
            }

            &.blue {
              &:before {
                background-color: var(--blue-500);
              }

              &:after {
                background-color: var(--blue-500);
              }
            }
          }
        }

        .y-label {
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;
          color: var(--type-active);
          position: absolute;
          bottom: -35px;
        }

        .pio-chart {
          margin-top: 40px;
          min-height: 0;

          > div {
            position: relative;
            height: 280px;
          }
        }

        .health-plan-image {
          width: 64px;
          border-radius: 8px;
        }
      }

      .annotations-list {
        .ps {
          height: 450px;
          padding: 0 0 0 24px;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            padding: 15px 24px 15px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            cursor: pointer;
          }
        }
        .annotations {
          font-family: Nunito Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          margin: 0px 20px;
          padding: 0px;

          &.filled {
            border-top: solid 1px #C6CEEB;
          }

          li{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            padding: 16px 24px;

            border-bottom: 1px solid #d9dff2;
            .title {
              margin: 0px;
              font-size: 16px !important;
              font-style: normal;
              font-weight: 600;
              color: #525C7A;
              display: flex;
              justify-content: space-between;
              
              .doctor {
                float: left;
                color: #0B30B2;
                margin-right: 5px;
                font-family: Nunito Sans;
                font-size: 18px !important;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0em;
              }

              .icon {
                  width: 20px;
                  height: 20px;
                  fill: var(--blue-500);
                  cursor: pointer;

                  &.trash {
                    fill: none;
                    stroke: var(--states-red-soft);
                  }

                  &.edit {
                    fill: none;
                    stroke: var(--blue-500);
                  }

                  &.close {
                    fill: var(--type-active);
                  }
                }

              .anotation {
                float: left;
                margin-right: 5px;
                font-family: Nunito Sans;
                font-size: 18px !important;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0em;
              }

              p {
                float: left;
                // color: #0B30B2;
                margin-right: 5px;
                font-family: Nunito Sans;
                font-size: 12px !important;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0em;
              }

              .options {
                cursor: pointer;
                width: 18px;
                height: 24px;
                margin-right: 5px;
                position: relative;
                .more-icon {
                  width: 24px;
                  height: 24px;
                  stroke: var(--neutral-500);
                  transition: all 0.5s;
                  cursor: pointer;
                }

                .menu {
                  position: absolute;
                  right: 0px;
                  width: 600px;
                  background: var(--neutral-000);
                  border: 1px solid var(--neutral-200);
                  box-sizing: border-box;
                  box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                  border-radius: 8px;
                  padding: 15px 20px;
                  opacity: 0;
                  transition: all 0.5s;
                  flex-direction: column;
                  align-items: start;
                  display: none;

                  .mtb-12 {
                    margin: 12px 0;
                  }

                  .title {
                    font-family: Nunito Sans;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #0B30B2;
                    padding: 10px 0px;
                  }

                  .content {
                    color: #525C7A;
                    font-family: Nunito Sans;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 150%;
                    margin: 10px 0px;
                    border: none;
                    padding: 0px;
                  }
                }

                &:hover {
                  .menu {
                    opacity: 1;
                    display: flex;
                    z-index: 10;
                  }
                }
              }

            }

            .content {
              padding-bottom: 30px;
              padding-top: 10px;
              border-bottom: 1px dashed #C6CEEB;

              &.last {
              border-bottom: none;
              }

              textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #C6CEEB;
                box-sizing: border-box;
                border-radius: 8px;
                color: #525C7A;
                resize: none;
              }

              textarea:focus {
                outline: none !important;
              }

              button {
                margin-top: 10px;
                width: 160px;
                background: #0B30B2;
                color: #ffffff;
                border-radius: 8px;
                padding: 10px;
              }

              .edit-info {
                margin-top: 10px;
                color: #525C7A;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
              }
            }
          }
        }
      }

      .info-message-box {
        background-color: #e6f9ff;
        border-radius: 8px;
        padding: 16px 26px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        align-items: center;
        margin-right: 24px;

        &.warning {
          background-color: var(--light-orange-200);
        }

        span {
          color: var(--blue-500);
          font-weight: 700;
          cursor: pointer;
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 25px;
          stroke: #0088b3;
        }
      }

      .patient-data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .label {
          font-weight: 600;
          font-size: 16px;
          color: var(--type-placeholder);
        }

        .value {
          font-weight: 400;
          font-size: 16px;
          color: var(--type-active);
        }
      }

      .patient-photo {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        margin-bottom: 16px;
      }

      .patient-name {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 24px;
        color: var(--dark-blue);
        margin-bottom: 4px;
        text-align: center;
      }

      .medical-record {
        font-family: 'Nunito Sans';
        font-weight: 400;
        font-size: 12px;
        color: var(--type-placeholder);
        margin-bottom: 16px;

        span {
          font-weight: 600;
          color: var(--type-active);
        }
      }

      .inactive-patient {
        font-family: 'Nunito Sans';
        font-weight: 600;
        font-size: 14px;
        color: var(--type-placeholder);
        line-height: 21px;
      }

      .mb-24 {
        margin-bottom: 24px;
      }

      .heat-map{
        display: flex;

        ul {
          margin: 32px 15px 0px 0px;
          padding: 0px;

          li{
            margin-top: 9px;
            list-style: none;
            font-family: Nunito Sans;
            color: #8696AC;
            font-size: 12px;
          }
        }

        .calendarHeatmap {
            width: 1500px;
            margin-left: -50px;

            .vch__months__labels__wrapper{


              .vch__month__label{
                font-size: 6px;
                font-family: Nunito Sans;
                fill: #8696AC;
              }
            }
            .vch__days__labels__wrapper{
              display: none;
            }
            .vch__legend__wrapper{
              display: none;
            }
          }
      }

      .mb-16 {
        margin-bottom: 16px;
      }

      .mt-16 {
        margin-top: 16px;
      }

      .glauco {
        width: 100%;
        border: 1px solid var(--neutral-200);
        border-radius: 8px;
        padding: 20px;
        position: relative;
        margin-bottom: 24px;

        .title {
          position: absolute;
          background-color: var(--neutral-000);
          top: -7px;
          left: 50%;
          transform: translateX(-50%);
          padding: 0 5px;
          font-weight: 600;
          font-size: 10px;

          .icon {
            width: 12.61px;
            height: 12px;
            margin-left: 4px;
          }
        }

        .number {
          font-size: 18px;
          line-height: 18px;
          font-weight: 600;
          color: var(--type-active);
          margin-bottom: 0;
          margin-right: 5px;
        }

        .gauge-area {
          width: 32px;
          height: 16px;
          margin-top: -4px;
        }

        .badge {
          width: 47px;
          height: 20px;
          border-radius: 10px;
          font-weight: 700;
          font-size: 12px;
          color: var(--neutral-000);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;

          .arrow {
            width: 8px;
            height: 8px;
            stroke: var(--neutral-000);
            margin-right: 4px;
          }

          &.green {
            background-color: var(--states-success);
          }
        }



        .info {
          font-size: 12px;
          color: var(--type-placeholder);
          margin-top: 4px;
          margin-bottom: 0;
        }
      }

      .icon-left {
        justify-content: center;
        align-items: center;
        display: flex;
        svg {
          height: auto;
          width: auto;
          transform: scale(1.2);
          margin-right: 12px;
        }
      }
    }

    .tackMapText {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: var(--type-active);

      span {
        font-size: 18px;
        line-height: 18px;
        color: var(--type-active);
        margin-right: 8px;
      }
    }

    .multiselect {
      width: 100%;

      .chevron {
        right: 0;
      }

      .multiselect__tags {
        padding: 8px 8px 8px 0;
      }

      &.multiselect--active {
        width: 120%;

        .multiselect__tags {
          padding: 8px 8px 8px 0;
          border: 1px solid var(--neutral-000);
        }
      }
    }

    .list-medical-records {
      margin: -24px 0 24px 0;

      .ps {
        height: 545px;
        padding: 0 0 0 24px;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          padding: 15px 24px 15px 30px;
          border-left: 1px solid var(--neutral-200);
          border-bottom: 1px solid var(--neutral-200);
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          cursor: pointer;

          &:hover {
            background-color: var(--neutral-100);
          }

          &.EXAM {
            &:after {
              width: 8px;
              height: 8px;
              background-color: var(--light-blue);
              position: absolute;
              border-radius: 8px;
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              content: ' ';
            }
          }

          &.CLOSED {
            &:after {
              width: 8px;
              height: 8px;
              background-color: #00C773;
              position: absolute;
              border-radius: 8px;
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              content: ' ';
            }
          }

          &.OPENED {
            &:after {
              width: 8px;
              height: 8px;
              background-color: #FF9900;
              position: absolute;
              border-radius: 8px;
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              content: ' ';
            }
          }

          &.PAUSED {
            &:after {
              width: 8px;
              height: 8px;
              background-color: #FF9900;
              position: absolute;
              border-radius: 8px;
              left: -5px;
              top: 50%;
              transform: translateY(-50%);
              content: ' ';
            }
          }

          &:before {
            width: 16px;
            height: 16px;
            background-color: var(--neutral-000);
            position: absolute;
            border-radius: 8px;
            border: 2px solid var(--neutral-200);
            left: -9px;
            top: 50%;
            transform: translateY(-50%);
            content: ' ';
          }

          &:after {
            width: 8px;
            height: 8px;
            background-color: #305BF2;
            position: absolute;
            border-radius: 8px;
            left: -5px;
            top: 50%;
            transform: translateY(-50%);
            content: ' ';
          }

          &:last-child {
            border-bottom: 0;
          }

          .date {
            font-weight: 600;
            font-size: 18px;
            color: var(--type-active);
            margin-bottom: 4px;
          }

          .time {
            font-weight: 600;
            font-size: 12px;
            color: var(--type-placeholder);
          }

          .text {
            text-overflow: ellipsis;
            font-weight: 600;
            font-size: 12px;
            text-align: right;
            color: var(--type-placeholder);
            display: flex;
            align-content: center;
            justify-content: flex-end;
            align-items: center;

            .fc-badge {
              width: 8px;
              height: 8px;
              border-radius: 8px;
              border: 1px solid #525c7a;
              margin-right: 5px;
              float: left;

              &.PRE_CONSULTATION {
                border-color: #21a715;
                background-color: #0e862c;
              }

              &.ANNOTATION {
                border-color: #305bf2;
                background-color: #305bf2;
              }

              &.OPENED {
                border-color: #FF9900;
                background-color: #FF9900;
              }

              &.CLOSED {
                border-color: #00C773;
                background-color: #00C773;
              }

              &.SURGERY {
                border-color: #FF9900;
                background-color: #FFCC18;
              }
            }
          }
        }
      }
    }

    .list-area {
      margin: -24px 0 24px 0;

      .info-message-box {
        margin-left: 24px;
      }

      .ps {
        /* overflow: unset !important; */
        height: 165px;
        transition: 0.5s;

        &.expanded {
          padding-bottom: 105px;
          height: 300px !important;
        }
      }

      .list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          padding: 15px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &:before {
            width: calc(100% - 48px);
            height: 1px;
            background-color: var(--neutral-200);
            position: absolute;
            bottom: 0;
            left: 24px;
            content: ' ';
          }

          &:hover {
            background-color: var(--neutral-100);
          }

          .container {
            .container-data-files {
              .data-files {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
            }

            .options {
              cursor: pointer;
              position: relative;
              display: inline-block;

              .more-icon-dots {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }

              .menu-dots {
                width: 160px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                z-index: 1;
                display: none;

                .mtb-12 {
                  margin: 12px 0;
                }

                .btn {
                  display: flex;
                  font-weight: 600;
                  width: 100%;
                  padding: 24px 24px 12px 24px;

                  &.remove {
                    color: var(--states-error);
                  }
                }

                .btn + .btn {
                  display: flex;
                  font-weight: 600;
                  width: 100%;
                  padding: 12px 24px 24px 24px;

                  &.remove {
                    color: var(--states-error);
                  }
                }

              }

              &:hover {
                .more-icon-dots {
                  transform: rotate(90deg);
                }

                .menu-dots {
                  opacity: 1;
                  display: flex;
                }
              }
            }
          }

          .options {
            cursor: pointer;
            width: 18px;
            height: 24px;
            margin-right: 5px;
            position: relative;
            display: inline-block;

            .more-icon {
              width: 18px;
              height: 18px;
              transition: all 0.5s;
              cursor: pointer;
            }

            .menu {
              width: 133px;
              position: absolute;
              background: var(--neutral-000);
              border: 1px solid var(--neutral-200);
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
              border-radius: 8px;
              padding: 5px;
              opacity: 0;
              transition: all 0.5s;
              flex-direction: column;
              align-items: start;
              display: none;
              background-color: var(--neutral-600);

              p {
                color: #ffffff;
                font-family: Nunito Sans;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
              }
            }

            &:hover {
              .menu {
                opacity: 1;
                display: flex;
                z-index: 10;
              }
            }
          }

          .name {
            font-weight: 600;
            font-size: 16px;
            color: var(--type-active);
          }

          .exam-info-container {
            span {
              color: var(--neutral-600);
              font-weight: 600;
              font-size: 12px;
              margin-left: 5px;
              padding: 0 5px;
              border-radius: 100px;
              width: fit-content !important;
              height: fit-content !important;
              &.blue-background {
                background-color: var(--neutral-200);
              }

              &.orange-background {
                background-color: var(--light-orange-200);
              }
            }
          }

          .info {
            font-weight: 600;
            font-size: 12px;
            color: var(--type-placeholder);
            white-space: nowrap;

            > span {
              width: 8px;
              height: 8px;
              margin-right: 8px;
              background-color: var(--states-success);
              border-radius: 4px;
              display: inline-block;

              &.orange {
                background-color: var(--light-orange-500);
              }
            }
          }
        }
      }

      .expand {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #305BF2;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          margin-right: 3px;

          svg {
            stroke: #305BF2;
            width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
  .opened {
    background-color: var(--neutral-100) !important;
  }
  .attendance-file-option-edit {
    width: 22px;
    height: 22px;
    stroke: var(--blue-500);
  }
  .attendance-file-option-delete {
    width: 22px;
    height: 22px;
    margin-left: 5px;
  }
  .health-plan-name {
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .historico {
    display: flex;
    flex-direction: row;
    align-items: center;
    .clipboard {
      width: 17px;
      height: 17px;
      transform: scale(0.75);
      stroke: var(--blue-500) !important;
    }
  }
}
.download-icon {
  height: 16px;
  width: 16px;
  margin-right: 7px;
  margin-bottom: 3px;
  stroke: var(--blue-500);
}
.plus-icon {
  width: 25px;
}

.patient-id-button {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background-color: white !important;
    color: #305BF2 !important;
    border: 3px solid #305BF2 !important;
    border-radius: 8px;
    font-size: 13px;
    line-height: 20px;

    &:hover {
      background-color: rgba(lightgray, 0.2) !important;
      border: 3px solid #305BF2 !important;
      color: #305BF2 !important;
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
</style>
